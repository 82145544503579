const actions = {
  CREATE_TENANT_INVITE: "CREATE_TENANT_INVITE",
  CREATE_TENANT_INVITE_SUCCESS: "CREATE_TENANT_INVITE_SUCCESS",
  CREATE_TENANT_INVITE_FAILURE: "CREATE_TENANT_INVITE_FAILURE",

  CREATE_TENANT_USER_BY_INVITATION: "CREATE_TENANT_USER_BY_INVITATION",
  CREATE_TENANT_USER_BY_INVITATION_SUCCESS:
    "CREATE_TENANT_USER_BY_INVITATION_SUCCESS",
  CREATE_TENANT_USER_BY_INVITATION_FAILURE:
    "CREATE_TENANT_USER_BY_INVITATION_FAILURE",

  INITIALIZE_STATES: "INITIALIZE_STATES",
};

export default actions;
