import actions from "./actions";

const initialState = {
  service_partner_deviations: [],
  service_partner: [],
  isFetchingDeviations: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_SERVICE_PARTNER:
      return {
        ...state,
        isFetchingDeviations: true,
      };
    case actions.FETCH_BUILDING_SERVICE_PARTNER_SUCCESS:
      return {
        ...state,
        isFetchingDeviations: false,
        service_partner_deviations: action.payload_deviations,
        service_partner: action.payload
      };
    case actions.FETCH_BUILDING_SERVICE_PARTNER_FAILURE:
      return { ...state, isFetchingDeviations: false };

    case actions.ADD_BUILDING_SERVICE_PARTNER:
      return {
        ...state,
        isFetchingDeviations: true,
      };
    case actions.ADD_BUILDING_SERVICE_PARTNER_SUCCESS:
      return {
        ...state,
        isFetchingDeviations: false,
      };
    case actions.ADD_BUILDING_SERVICE_PARTNER_FAILURE:
      return { ...state, isFetchingDeviations: false };

    case actions.UPDATE_BUILDING_SERVICE_PARTNER:
      return {
        ...state,
        isFetchingDeviations: true,
      };
    case actions.UPDATE_BUILDING_SERVICE_PARTNER_SUCCESS:
      return {
        ...state,
        isFetchingDeviations: false,
      };
    case actions.UPDATE_BUILDING_SERVICE_PARTNER_FAILURE:
      return { ...state, isFetchingDeviations: false };
      
    default:
      return state;
  }
}

export default Reducer;
