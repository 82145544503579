import actions from "./actions";

const initialState = {
  buildingThermographyReport: [],
  isFecthingBuildingThermographyReport: false,
  buildingCreatedId: null,
  buildingThermographyReportFile: null,
  buildingThermographyReportCustomers: [],
  buildingThermographyReportHistories: [],
  isFecthingBuildingThermographyReportHistories: false,
  isFinishedBuildingThermographyReports: null,
  isFinishedBuildingThermographyReportsFile: null,
  isFinishedBuildingThermographyReportsFileConfirm: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_THERMOGRAPHY_REPORT:
      return {
        ...state,
        isFecthingBuildingThermographyReport: true,
      };
    case actions.FETCH_BUILDING_THERMOGRAPHY_REPORT_SUCCESS:
      return {
        ...state,
        isFecthingBuildingThermographyReport: false,
        buildingThermographyReport: action.payload,
        buildingThermographyReportCustomers: action.payloadCustomer,
      };
    case actions.FETCH_BUILDING_THERMOGRAPHY_REPORT_FAILURE:
      return { ...state, isFecthingBuildingThermographyReport: false };

    case actions.FETCH_BUILDING_FIRE_HISTORIES:
      return {
        ...state,
        isFecthingBuildingThermographyReportHistories: true,
      };
    case actions.FETCH_BUILDING_FIRE_HISTORIES_SUCCESS:
      return {
        ...state,
        isFecthingBuildingThermographyReportHistories: false,
        buildingThermographyReportHistories: action.payload,
      };
    case actions.FETCH_BUILDING_FIRE_HISTORIES_FAILURE:
      return { ...state, isFecthingBuildingThermographyReportHistories: false };

    case actions.CREATE_BUILDING_THERMOGRAPHY_REPORT:
      return { ...state, isFinishedBuildingThermographyReports: null };
    case actions.CREATE_BUILDING_THERMOGRAPHY_REPORT_SUCCESS:
      return { ...state, isFinishedBuildingThermographyReports: true, buildingCreatedId: action.payload };
    case actions.CREATE_BUILDING_THERMOGRAPHY_REPORT_FAILURE:
      return { ...state, isFinishedBuildingThermographyReports: false };

    case actions.UPDATE_BUILDING_THERMOGRAPHY_REPORT:
      return { ...state, isFinishedBuildingThermographyReports: null };
    case actions.UPDATE_BUILDING_THERMOGRAPHY_REPORT_SUCCESS:
      return { ...state, isFinishedBuildingThermographyReports: true };
    case actions.UPDATE_BUILDING_THERMOGRAPHY_REPORT_FAILURE:
      return { ...state, isFinishedBuildingThermographyReports: false };

    default:
      return state;
  }
}

export default Reducer;
