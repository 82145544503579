import actions from "./actions";

const initialState = {
  taskForComment: {},
  taskComment: {},
  tasks: [],
  taskStatistics: [],
  taskStatisticsByDuedate: [],
  task_buildings: [],
  task_customers: [],
  isFetchingTaskComment: false,
  isFetchingTaskSatistics: false,
  isFetchingTaskSatisticsByDuedate: false,
  isFetchingTasks: false,
  isFetchingBuildings: false,
  createTask: "",
  isChangedTask: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_TASK_TASKS:
      return {
        ...state,
        isChangedTask: null,
        isFetchingTasks: true,
      };
    case actions.FETCH_TASK_TASKS_SUCCESS:
      return {
        ...state,
        isFetchingTasks: false,
        tasks: action.payload,
        task_buildings: action.payload_buildings,
        task_customers: action.payload_customers,
      };
    case actions.FETCH_TASK_TASKS_FAILURE:
      return { ...state, isFetchingTasks: false };

    case actions.FETCH_TASK_SATISTICS:
      return {
        ...state,
        isFetchingTaskSatistics: true,
      };
    case actions.FETCH_TASK_SATISTICS_SUCCESS:
      return {
        ...state,
        isFetchingTaskSatistics: false,
        taskStatistics: action.payload,
      };
    case actions.FETCH_TASK_SATISTICS_FAILURE:
      return { ...state, isFetchingTaskSatistics: false };

    case actions.FETCH_TASK_SATISTICS_BY_DUEDATE:
      return {
        ...state,
        isFetchingTaskSatisticsByDuedate: true,
      };
    case actions.FETCH_TASK_SATISTICS_BY_DUEDATE_SUCCESS:
      return {
        ...state,
        isFetchingTaskSatisticsByDuedate: false,
        taskStatisticsByDuedate: action.payload,
      };
    case actions.FETCH_TASK_SATISTICS_BY_DUEDATE_FAILURE:
      return { ...state, isFetchingTaskSatisticsByDuedate: false };

    case actions.ADD_TASK_TASKS:
      return {
        ...state,
        isChangedTask: null,
        isFetchingTasks: true,
      };
    case actions.ADD_TASK_TASKS_SUCCESS:
      return {
        ...state,
        isFetchingTasks: false,
        isChangedTask: true,
        createTask: action.payload,
      };
    case actions.ADD_TASK_TASKS_FAILURE:
      return { ...state, isFetchingTasks: false, isChangedTask: false };

    case actions.UPDATE_TASK_TASKS:
      return {
        ...state,
        isChangedTask: null,
        isFetchingTasks: true,
      };
    case actions.UPDATE_TASK_TASKS_SUCCESS:
      return {
        ...state,
        isFetchingTasks: false,
        isChangedTask: true,
        createTask: action.payload,
      };
    case actions.UPDATE_TASK_TASKS_FAILURE:
      return { ...state, isFetchingTasks: false, isChangedTask: false };

    default:
      return state;
  }
}

export default Reducer;
