import actions from "./actions";

const initialState = {
  mainTheme: [],
  subTheme: [],

  isCreateMainThemeDone: null,
  isUpdateMainThemeDone: null,
  isDeleteMainThemeDone: null,
  isCreateSubThemeDone: null,
  isUpdateSubThemeDone: null,
  isDeleteSubThemeDone: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_MAIN_THEME_SUCCESS:
      return {
        ...state,
        mainTheme: action.payload
      };

    case actions.CREATE_MAIN_THEME:
      return {
        ...state,
        isCreateMainThemeDone: null
      };
    case actions.CREATE_MAIN_THEME_SUCCESS:
      return {
        ...state,
        isCreateMainThemeDone: true,
      };
    case actions.CREATE_MAIN_THEME_FAILURE:
      return {
        ...state,
        isCreateMainThemeDone: false
      };
      
    case actions.UPDATE_MAIN_THEME:
      return {
        ...state,
        isUpdateMainThemeDone: null
      };
    case actions.UPDATE_MAIN_THEME_SUCCESS:
      return {
        ...state,
        isUpdateMainThemeDone: true,
      };
    case actions.UPDATE_MAIN_THEME_FAILURE:
      return {
        ...state,
        isUpdateMainThemeDone: false
      };
    case actions.DELETE_MAIN_THEME:
      return {
        ...state,
        isDeleteMainThemeDone: null
      };
    case actions.DELETE_MAIN_THEME_SUCCESS:
      return {
        ...state,
        isDeleteMainThemeDone: true,
      };
    case actions.DELETE_MAIN_THEME_FAILURE:
      return {
        ...state,
        isDeleteMainThemeDone: false
      };
// ----------------Sub Theme Reducers ---------------------
    case actions.FETCH_SUB_THEME_SUCCESS:
      return {
        ...state,
        subTheme: action.payload
      };

    case actions.CREATE_SUB_THEME:
      return {
        ...state,
        isCreateSubThemeDone: null
      };
    case actions.CREATE_SUB_THEME_SUCCESS:
      return {
        ...state,
        isCreateSubThemeDone: true,
      };
    case actions.CREATE_SUB_THEME_FAILURE:
      return {
        ...state,
        isCreateSubThemeDone: false
      };
      
    case actions.UPDATE_SUB_THEME:
      return {
        ...state,
        isUpdateSubThemeDone: null
      };
    case actions.UPDATE_SUB_THEME_SUCCESS:
      return {
        ...state,
        isUpdateSubThemeDone: true,
      };
    case actions.UPDATE_SUB_THEME_FAILURE:
      return {
        ...state,
        isUpdateSubThemeDone: false
      };
    case actions.DELETE_SUB_THEME:
      return {
        ...state,
        isDeleteSubThemeDone: null
      };
    case actions.DELETE_SUB_THEME_SUCCESS:
      return {
        ...state,
        isDeleteSubThemeDone: true,
      };
    case actions.DELETE_SUB_THEME_FAILURE:
      return {
        ...state,
        isDeleteSubThemeDone: false
      };

      
    default:
      return state;
  }
}

export default Reducer;
