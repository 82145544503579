const actions = {
  FETCH_ALL_DATA_FOR_CREATE_TENANTS: "FETCH_ALL_DATA_FOR_CREATE_TENANTS",
  FETCH_ALL_DATA_FOR_CREATE_TENANTS_SUCCESS: "FETCH_ALL_DATA_FOR_CREATE_TENANTS_SUCCESS",
  FETCH_ALL_DATA_FOR_CREATE_TENANTS_FAILURE: "FETCH_ALL_DATA_FOR_CREATE_TENANTS_FAILURE",

  FETCH_CREATE_TENANTS: "FETCH_CREATE_TENANTS",
  FETCH_CREATE_TENANTS_SUCCESS: "FETCH_CREATE_TENANTS_SUCCESS",
  FETCH_CREATE_TENANTS_FAILURE: "FETCH_CREATE_TENANTS_FAILURE",

  CREATE_CONTACT_FOR_TENANTS: "CREATE_CONTACT_FOR_TENANTS",
  CREATE_CONTACT_FOR_TENANTS_SUCCESS: "CREATE_CONTACT_FOR_TENANTS_SUCCESS",
  CREATE_CONTACT_FOR_TENANTS_FAILURE: "CREATE_CONTACT_FOR_TENANTS_FAILURE",

  UPDATE_CONTACT_FOR_TENANTS: "UPDATE_CONTACT_FOR_TENANTS",
  UPDATE_CONTACT_FOR_TENANTS_SUCCESS: "UPDATE_CONTACT_FOR_TENANTS_SUCCESS",
  UPDATE_CONTACT_FOR_TENANTS_FAILURE: "UPDATE_CONTACT_FOR_TENANTS_FAILURE",

  DELETE_CONTACT_FOR_TENANTS: "DELETE_CONTACT_FOR_TENANTS",
  DELETE_CONTACT_FOR_TENANTS_SUCCESS: "DELETE_CONTACT_FOR_TENANTS_SUCCESS",
  DELETE_CONTACT_FOR_TENANTS_FAILURE: "DELETE_CONTACT_FOR_TENANTS_FAILURE",

  CREATE_TENANTS_GENERAL: "CREATE_TENANTS_GENERAL",
  CREATE_TENANTS_GENERAL_SUCCESS: "CREATE_TENANTS_GENERAL_SUCCESS",
  CREATE_TENANTS_GENERAL_FAILURE: "CREATE_TENANTS_GENERAL_FAILURE",

  CREATE_TENANTS_ADDRESS: "CREATE_TENANTS_ADDRESS",
  CREATE_TENANTS_ADDRESS_SUCCESS: "CREATE_TENANTS_ADDRESS_SUCCESS",
  CREATE_TENANTS_ADDRESS_FAILURE: "CREATE_TENANTS_ADDRESS_FAILURE",

  INITIALIZE_STATES: "INITIALIZE_STATES",
};

export default actions;
