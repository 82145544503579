import actions from "./actions";

const initialState = {
  buildingFireManager: [],
  isFecthingBuildingFireManager: false,

  isChangedBuildingfireManager: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_FIRE_MANAGER:
      return {
        ...state,
        isFecthingBuildingFireManager: true,
        isChangedBuildingfireManager: null,
      };
    case actions.FETCH_BUILDING_FIRE_MANAGER_SUCCESS:
      return {
        ...state,
        isFecthingBuildingFireManager: false,
        buildingFireManager: action.payload,
      };

    case actions.FETCH_BUILDING_FIRE_MANAGER_FAILURE:
      return { ...state, isFecthingBuildingFireManager: false };

    case actions.CREATE_BUILDING_FIRE_MANAGER:
      return {
        ...state,
        isChangedBuildingfireManager: null,
        isFecthingBuildingFireManager: true
      };

    case actions.CREATE_BUILDING_FIRE_MANAGER_SUCCESS:
      return { ...state, isChangedBuildingfireManager: true };

    case actions.CREATE_BUILDING_FIRE_MANAGER_FAILURE:
      return {
        ...state,
        isChangedBuildingfireManager: false,
        isFecthingBuildingFireManager: false,
        buildingFireManager: action.payload
      };


    case actions.UPDATE_BUILDING_FIRE_MANAGER:
      return {
        ...state,
        isChangedBuildingfireManager: null,
        isFecthingBuildingFireManager: false
      };

    case actions.UPDATE_BUILDING_FIRE_MANAGER_SUCCESS:
      return { ...state, isChangedBuildingfireManager: true };

    case actions.UPDATE_BUILDING_FIRE_MANAGER_FAILURE:
      return { ...state, isChangedBuildingfireManager: false };
    default:
      return state;
  }
}

export default Reducer;
