import actions from "./actions";

const initialState = {
  buildingPreparedness: [],
  isFecthingBuildingPreparedness: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_PREPAREDNESS:
      return {
        ...state,
        isFecthingBuildingPreparedness: true,
      };
    case actions.FETCH_BUILDING_PREPAREDNESS_SUCCESS:
      return {
        ...state,
        isFecthingBuildingPreparedness: false,
        buildingPreparedness: action.payload,
      };
    case actions.FETCH_BUILDING_PREPAREDNESS_FAILURE:
      return { ...state, isFecthingBuildingPreparedness: false };

    case actions.CREATE_BUILDING_PREPAREDNESS:
      return {
        ...state,
        isFecthingBuildingPreparedness: true,
      };
    case actions.CREATE_BUILDING_PREPAREDNESS_SUCCESS:
      return {
        ...state,
        isFecthingBuildingPreparedness: false,
        buildingPreparedness: action.payload,
      };
    case actions.CREATE_BUILDING_PREPAREDNESS_FAILURE:
      return { ...state, isFecthingBuildingPreparedness: false };

    default:
      return state;
  }
}

export default Reducer;
