import actions from "./actions";

const initialState = {
  properties: [],
  isFetchingProperties: false,

  propertiesOfUser: [],
  isFetchingPropertiesOfUser: false,

  property: null,
  isFetchingProperty: false,

  payload: null,
  isCreatingSuccess: false,

  userForBuilding: null,
  isFetchingUser: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_PROPERTIES:
      return {
        ...state,
        isFetchingProperties: true,
      };
    case actions.FETCH_PROPERTIES_SUCCESS:
      return {
        ...state,
        isFetchingProperties: false,
        properties: action.payload,
      };
    case actions.FETCH_PROPERTIES_FAILURE:
      return { ...state, isFetchingProperties: false };
    
      case actions.FETCH_PROPERTIES_WITH_USER:
      return {
        ...state,
        isFetchingPropertiesOfUser: true,
      };
    case actions.FETCH_PROPERTIES_WITH_USER_SUCCESS:
      return {
        ...state,
        isFetchingPropertiesOfUser: false,
        propertiesOfUser: action.payload,
      };
    case actions.FETCH_PROPERTIES_WITH_USER_FAILURE:
      return { ...state, isFetchingPropertiesOfUser: false };

    case actions.FETCH_PROPERTY:
      return {
        ...state,
        isFetchingProperty: true,
      };
    case actions.FETCH_PROPERTY_SUCCESS:
      return {
        ...state,
        isFetchingProperty: false,
        property: action.payload,
      };
    case actions.FETCH_PROPERTY_FAILURE:
      return { ...state, isFetchingProperty: false };

    case actions.CREATE_PROPERTY:
      return {
        ...state,
        isCreatingProperty: true,
        isCreatingSuccess: false,
      };
    case actions.CREATE_PROPERTY_SUCCESS:
      return {
        ...state,
        isCreatingProperty: false,
        payload: action.payload,
        propertiesOfUser: action.payloadBuildings,
        isCreatingSuccess: true,
      };
    case actions.CREATE_PROPERTY_FAILURE:
      return {
        ...state,
        isCreatingProperty: false,
        isCreatingSuccess: false,
      }

    case actions.FETCH_USER_FOR_PROPERTY:
      return {
        ...state,
        isFetchingUser: true,
      };
    case actions.FETCH_USER_FOR_PROPERTY_SUCCESS:
      return {
        ...state,
        isFetchingUser: false,
        userForBuilding: action.payload,
      };
    case actions.FETCH_USER_FOR_PROPERTY_FAILURE:
      return { ...state, isFetchingUser: false };
    default:
      return state;
  }
}

export default Reducer;
