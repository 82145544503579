import actions from "./actions";

const initialState = {
  contactsForCreateTenants: [],
  createdContactsForTenants: [],

  createdTenants: [],
  isFetchingCreatedTenants: [],

  createdNewContactforTenants: null,
  deletedNewContactForTenants: null,
  editedNewContactForTenants: null,

  createdNewGeneralTenants: null,
  createdNewAddressTenants: null,

  isFetchingTenants: false,
  isCreatedContactForTenants: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CREATE_TENANTS:
      return {
        ...state,
        isFetchingCreatedTenants: true,
      };
    case actions.FETCH_CREATE_TENANTS_SUCCESS:
      return {
        ...state,
        isFetchingCreatedTenants: false,
        createdTenants: action.payload,
      };
    case actions.FETCH_CREATE_TENANTS_FAILURE:
      return { ...state, isFetchingCreatedTenants: false };

    case actions.FETCH_ALL_DATA_FOR_CREATE_TENANTS:
      return {
        ...state,
        isFetchingTenants: true,
        isCreatedContactForTenants: null,
        createdNewGeneralTenants: null,
      };
    case actions.FETCH_ALL_DATA_FOR_CREATE_TENANTS_SUCCESS:
      return {
        ...state,
        isFetchingTenants: false,
        contactsForCreateTenants: action.payload,
      };
    case actions.FETCH_ALL_DATA_FOR_CREATE_TENANTS_FAILURE:
      return { ...state, isFetchingTenants: false };

    case actions.CREATE_CONTACT_FOR_TENANTS:
      return {
        ...state, isCreatedContactForTenants: null,
        createdNewContactforTenants: null,
      }

    case actions.CREATE_CONTACT_FOR_TENANTS_SUCCESS:
      return {
        ...state,
        isCreatedContactForTenants: true,
        createdNewContactforTenants: action.payload
      }
    case actions.CREATE_CONTACT_FOR_TENANTS_FAILURE:
      return {
        ...state,
        isCreatedContactForTenants: false,
        createdNewContactforTenants: null,
      }

    case actions.DELETE_CONTACT_FOR_TENANTS:
      return {
        ...state,
        deletedNewContactForTenants: null,
      }
    case actions.DELETE_CONTACT_FOR_TENANTS_SUCCESS:
      return {
        ...state,
        deletedNewContactForTenants: action.payload
      }
    case actions.DELETE_CONTACT_FOR_TENANTS_FAILURE:
      return {
        ...state,
        deletedNewContactForTenants: null
      }

    case actions.UPDATE_CONTACT_FOR_TENANTS:
      return {
        ...state,
        editedNewContactForTenants: null,
      }
    case actions.UPDATE_CONTACT_FOR_TENANTS_SUCCESS:
      return {
        ...state,
        editedNewContactForTenants: action.payload
      }
    case actions.UPDATE_CONTACT_FOR_TENANTS_FAILURE:
      return {
        ...state,
        editedNewContactForTenants: null
      }

    case actions.CREATE_TENANTS_GENERAL:
      return {
        ...state,
        createdNewGeneralTenants: null,
      }
    case actions.CREATE_TENANTS_GENERAL_SUCCESS:
      return {
        ...state,
        createdNewGeneralTenants: action.payload,
      }
    case actions.CREATE_TENANTS_GENERAL_FAILURE:
      return {
        ...state,
        createdNewGeneralTenants: false
      }

    case actions.CREATE_TENANTS_ADDRESS:
      return {
        ...state,
        createdNewAddressTenants: null,
      }
    case actions.CREATE_TENANTS_ADDRESS_SUCCESS:
      return {
        ...state,
        createdNewAddressTenants: action.payload,
      }
    case actions.CREATE_TENANTS_ADDRESS_FAILURE:
      return {
        ...state,
        createdNewAddressTenants: null
      }

    case actions.INITIALIZE_STATES:
      return {
        ...state,
        deletedNewContactForTenants: null,
        editedNewContactForTenants: null,
        createdNewAddressTenants: null,
      }
    default:
      return state;
  }
}

export default Reducer;
