const actions = {
  FETCH_BUILDING_SERVICE_PARTNER: "FETCH_BUILDING_SERVICE_PARTNER",
  FETCH_BUILDING_SERVICE_PARTNER_SUCCESS: "FETCH_BUILDING_SERVICE_PARTNER_SUCCESS",
  FETCH_BUILDING_SERVICE_PARTNER_FAILURE: "FETCH_BUILDING_SERVICE_PARTNER_FAILURE",

  ADD_BUILDING_SERVICE_PARTNER: "ADD_BUILDING_SERVICE_PARTNER",
  ADD_BUILDING_SERVICE_PARTNER_SUCCESS: "ADD_BUILDING_SERVICE_PARTNER_SUCCESS",
  ADD_BUILDING_SERVICE_PARTNER_FAILURE: "ADD_BUILDING_SERVICE_PARTNER_FAILURE",

  UPDATE_BUILDING_SERVICE_PARTNER: "UPDATE_BUILDING_SERVICE_PARTNER",
  UPDATE_BUILDING_SERVICE_PARTNER_SUCCESS: "UPDATE_BUILDING_SERVICE_PARTNER_SUCCESS",
  UPDATE_BUILDING_SERVICE_PARTNER_FAILURE: "UPDATE_BUILDING_SERVICE_PARTNER_FAILURE",
};

export default actions;
