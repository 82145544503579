const actions = {
  FETCH_DEVIATION_DEVIATIONS: "FETCH_DEVIATION_DEVIATIONS",
  FETCH_DEVIATION_DEVIATIONS_SUCCESS: "FETCH_DEVIATION_DEVIATIONS_SUCCESS",
  FETCH_DEVIATION_DEVIATIONS_FAILURE: "FETCH_DEVIATION_DEVIATIONS_FAILURE",

  FETCH_DEVIATION_SATISTICS: "FETCH_DEVIATION_SATISTICS",
  FETCH_DEVIATION_SATISTICS_SUCCESS: "FETCH_DEVIATION_SATISTICS_SUCCESS",
  FETCH_DEVIATION_SATISTICS_FAILURE: "FETCH_DEVIATION_SATISTICS_FAILURE",

  FETCH_DEVIATION_SATISTICS_BY_DUEDATE: "FETCH_DEVIATION_SATISTICS_BY_DUEDATE",
  FETCH_DEVIATION_SATISTICS_BY_DUEDATE_SUCCESS:
    "FETCH_DEVIATION_SATISTICS_BY_DUEDATE_SUCCESS",
  FETCH_DEVIATION_SATISTICS_BY_DUEDATE_FAILURE:
    "FETCH_DEVIATION_SATISTICS_BY_DUEDATE_FAILURE",

  ADD_DEVIATION_DEVIATIONS: "ADD_DEVIATION_DEVIATIONS",
  ADD_DEVIATION_DEVIATIONS_SUCCESS: "ADD_DEVIATION_DEVIATIONS_SUCCESS",
  ADD_DEVIATION_DEVIATIONS_FAILURE: "ADD_DEVIATION_DEVIATIONS_FAILURE",

  UPDATE_DEVIATION_DEVIATIONS: "UPDATE_DEVIATION_DEVIATIONS",
  UPDATE_DEVIATION_DEVIATIONS_SUCCESS: "UPDATE_DEVIATION_DEVIATIONS_SUCCESS",
  UPDATE_DEVIATION_DEVIATIONS_FAILURE: "UPDATE_DEVIATION_DEVIATIONS_FAILURE",
};

export default actions;
