const actions = {
  FETCH_TASK_TASKS: "FETCH_TASK_TASKS",
  FETCH_TASK_TASKS_SUCCESS: "FETCH_TASK_TASKS_SUCCESS",
  FETCH_TASK_TASKS_FAILURE: "FETCH_TASK_TASKS_FAILURE",

  FETCH_TASK_SATISTICS: "FETCH_TASK_SATISTICS",
  FETCH_TASK_SATISTICS_SUCCESS: "FETCH_TASK_SATISTICS_SUCCESS",
  FETCH_TASK_SATISTICS_FAILURE: "FETCH_TASK_SATISTICS_FAILURE",

  FETCH_TASK_SATISTICS_BY_DUEDATE: "FETCH_TASK_SATISTICS_BY_DUEDATE",
  FETCH_TASK_SATISTICS_BY_DUEDATE_SUCCESS:
    "FETCH_TASK_SATISTICS_BY_DUEDATE_SUCCESS",
  FETCH_TASK_SATISTICS_BY_DUEDATE_FAILURE:
    "FETCH_TASK_SATISTICS_BY_DUEDATE_FAILURE",

  FETCH_BUILDINGS: "FETCH_BUILDINGS",
  FETCH_BUILDINGS_SUCCESS: "FETCH_BUILDINGS_SUCCESS",
  FETCH_BUILDINGS_FAILURE: "FETCH_BUILDINGS_FAILURE",

  ADD_TASK_TASKS: "ADD_TASK_TASKS",
  ADD_TASK_TASKS_SUCCESS: "ADD_TASK_TASKS_SUCCESS",
  ADD_TASK_TASKS_FAILURE: "ADD_TASK_TASKS_FAILURE",

  UPDATE_TASK_TASKS: "UPDATE_TASK_TASKS",
  UPDATE_TASK_TASKS_SUCCESS: "UPDATE_TASK_TASKS_SUCCESS",
  UPDATE_TASK_TASKS_FAILURE: "UPDATE_TASK_TASKS_FAILURE",
};

export default actions;
