import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchAlldataForCreateTenants() {
  try {
    const response_contact = yield callApi(() => getRequest('building/contact'))
    yield put({
      type: actions.FETCH_ALL_DATA_FOR_CREATE_TENANTS_SUCCESS,
      payload: response_contact.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ALL_DATA_FOR_CREATE_TENANTS_FAILURE });
  }
}

function* createContactForTenants(action) {
  try {
    const response = yield callApi(() => postRequest('building/contact', action.payload));
    yield put({
      type: actions.CREATE_CONTACT_FOR_TENANTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_CONTACT_FOR_TENANTS_FAILURE });
  }
}

function* deleteContactForTenants(action) {
  try {
    const response = yield callApi(() => deleteRequest(`building/contact?id=${action.payload}`));
    yield put({
      type: actions.DELETE_CONTACT_FOR_TENANTS_SUCCESS,
      payload: response.data.id,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_CONTACT_FOR_TENANTS_FAILURE });
  }
}

function* updateContactForTenants(action) {
  try {
    const response = yield callApi(() => putRequest(`building/contact?id=${action.id}`, action.payload));
    console.log('response', response);
    yield put({
      type: actions.UPDATE_CONTACT_FOR_TENANTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTACT_FOR_TENANTS_FAILURE });
  }
}

function* createNewGeneralTenants(action) {
  try {
    const response = yield callApi(() => postRequest('building/tenant', action.payload));
    yield put({
      type: actions.CREATE_TENANTS_GENERAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_TENANTS_GENERAL_FAILURE });
  }
}

function* createNewAddressTenants(action) {
  try {
    const response = yield callApi(() => postRequest(`building/tenant/address?tenantId=${action.tenantId}`, action.payload));
    yield put({
      type: actions.CREATE_TENANTS_ADDRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_TENANTS_ADDRESS_FAILURE });
  }
}
// function* fetchProperty(action) {
//   try {
//     const response = yield callApi(() => getRequest(`building?id=${action.payload.id}`))
//     yield put({
//       type: actions.FETCH_PROPERTY_SUCCESS,
//       payload: response.data,
//     });
//   } catch (error) {
//     yield put({ type: actions.FETCH_PROPERTY_FAILURE });
//   }
// }



function* fetchTenantsForBuilding(action) {
  try {
    const response = yield callApi(() => getRequest(`building/tenant?buildingId=${action.buildingId}`));
    yield put({
      type: actions.FETCH_CREATE_TENANTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CREATE_TENANTS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_ALL_DATA_FOR_CREATE_TENANTS, fetchAlldataForCreateTenants)]);
  yield all([takeLatest(actions.CREATE_CONTACT_FOR_TENANTS, createContactForTenants)]);
  yield all([takeLatest(actions.DELETE_CONTACT_FOR_TENANTS, deleteContactForTenants)]);
  yield all([takeLatest(actions.UPDATE_CONTACT_FOR_TENANTS, updateContactForTenants)]);
  yield all([takeLatest(actions.CREATE_TENANTS_GENERAL, createNewGeneralTenants)]);
  yield all([takeLatest(actions.CREATE_TENANTS_ADDRESS, createNewAddressTenants)]);
  yield all([takeLatest(actions.FETCH_CREATE_TENANTS, fetchTenantsForBuilding)]);
}
