const actions = {
  FETCH_BASIC_DATA_OTHER: "FETCH_BASIC_DATA_OTHER",
  FETCH_BASIC_DATA_OTHER_SUCCESS: "FETCH_BASIC_DATA_OTHER_SUCCESS",
  FETCH_BASIC_DATA_OTHER_FAILURE: "FETCH_BASIC_DATA_OTHER_FAILURE",

  FETCH_BUILDING_INSTALLATION_OTHER: "FETCH_BUILDING_INSTALLATION_OTHER",
  FETCH_BUILDING_INSTALLATION_OTHER_SUCCESS: "FETCH_BUILDING_INSTALLATION_OTHER_SUCCESS",
  FETCH_BUILDING_INSTALLATION_OTHER_FAILURE: "FETCH_BUILDING_INSTALLATION_OTHER_FAILURE",

  CREATE_BUILDING_INSTALLATION_OTHER: "CREATE_BUILDING_INSTALLATION_OTHER",
  CREATE_BUILDING_INSTALLATION_OTHER_SUCCESS: "CREATE_BUILDING_INSTALLATION_OTHER_SUCCESS",
  CREATE_BUILDING_INSTALLATION_OTHER_FAILURE: "CREATE_BUILDING_INSTALLATION_OTHER_FAILURE",

  UPDATE_BUILDING_INSTALLATION_OTHER: "UPDATE_BUILDING_INSTALLATION_OTHER",
  UPDATE_BUILDING_INSTALLATION_OTHER_SUCCESS: "UPDATE_BUILDING_INSTALLATION_OTHER_SUCCESS",
  UPDATE_BUILDING_INSTALLATION_OTHER_FAILURE: "UPDATE_BUILDING_INSTALLATION_OTHER_FAILURE",  
};

export default actions;
