import actions from "./actions";

const initialState = {
  buildingManager: [],
  isFecthingBuildingManager: false,

  isChangedBuildingManager: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_MANAGER:
      return {
        ...state,
        isFecthingBuildingManager: true,
        isChangedBuildingManager: null,
      };
    case actions.FETCH_BUILDING_MANAGER_SUCCESS:
      return {
        ...state,
        isFecthingBuildingManager: false,
        buildingManager: action.payload,
      };
    case actions.FETCH_BUILDING_MANAGER_FAILURE:
      return { ...state, isFecthingBuildingManager: false };

    case actions.CREATE_BUILDING_MANAGER:
      return {
        ...state, isChangedBuildingManager: null,
        isFecthingBuildingManager: true,
      };

    case actions.CREATE_BUILDING_MANAGER_SUCCESS:
      return {
        ...state,
        isChangedBuildingManager: true,
        isFecthingBuildingManager: false,
        buildingManager: action.payload
      };

    case actions.CREATE_BUILDING_MANAGER_FAILURE:
      return {
        ...state,
        isChangedBuildingManager: false,
        isFecthingBuildingManager: false
      };


    case actions.UPDATE_BUILDING_MANAGER:
      return { ...state, isChangedBuildingManager: null };

    case actions.UPDATE_BUILDING_MANAGER_SUCCESS:
      return { ...state, isChangedBuildingManager: true };

    case actions.UPDATE_BUILDING_MANAGER_FAILURE:
      return { ...state, isChangedBuildingManager: false };


    default:
      return state;
  }
}

export default Reducer;
