import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchServicePartner(action) {
  try {
    const responseDeviations = yield callApi(() => getRequest(`workflow/deviation`));
    const responseServicePartiner = yield callApi(() => getRequest('building/service-partner'))
    yield put({
      type: actions.FETCH_BUILDING_SERVICE_PARTNER_SUCCESS,
      payload_deviations: responseDeviations.data,
      payload: responseServicePartiner.data,

    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_SERVICE_PARTNER_FAILURE });
  }
}

function* addServicePartner(action) {
  try {
    const response = yield callApi(() => postRequest('building/service-partner', action.payload))
    yield put({
      type: actions.ADD_BUILDING_SERVICE_PARTNER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADD_BUILDING_SERVICE_PARTNER_FAILURE });
  }
}

function* updatServicePartner(action) {
  try {
    const response = yield callApi(() => putRequest('building/service-partner', action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_SERVICE_PARTNER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_SERVICE_PARTNER_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_SERVICE_PARTNER, fetchServicePartner)]);
  yield all([takeLatest(actions.ADD_BUILDING_SERVICE_PARTNER, addServicePartner)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_SERVICE_PARTNER, updatServicePartner)]);
}
