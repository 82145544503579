import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchBasicDataOther() {
  try {
    const response_coverage_area = yield callApi(() => getRequest('cms/coverage-area'));
    const response_other_installation = yield callApi(() => getRequest('cms/other-installation'));
    const response_deviations = yield callApi(() => getRequest('workflow/deviation'));
    yield put({
      type: actions.FETCH_BASIC_DATA_OTHER_SUCCESS,
      payload_coverage_area: response_coverage_area.data,
      payload_other_installation: response_other_installation.data,
      payload_deviations: response_deviations.data
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BASIC_DATA_OTHER_FAILURE });
  }
}

function* fetchBuildingOther(action) {
  try {
    const response = yield callApi(() => getRequest(`building/other-installation?buildingId=${action.payload}`))
    yield put({
      type: actions.FETCH_BUILDING_INSTALLATION_OTHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_INSTALLATION_OTHER_FAILURE });
  }
}

function* createBuildingOther(action) {
  try {
    const response = yield callApi(() => postRequest(`building/other-installation?buildingId=${action.buildingId}`, action.payload))
    yield put({
      type: actions.CREATE_BUILDING_INSTALLATION_OTHER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_INSTALLATION_OTHER_FAILURE });
  }
}

function* updateBuildingOther(action) {
  try {
    const response = yield callApi(() => putRequest(`building/other-installation?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_INSTALLATION_OTHER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_INSTALLATION_OTHER_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BASIC_DATA_OTHER, fetchBasicDataOther)]);
  yield all([takeLatest(actions.FETCH_BUILDING_INSTALLATION_OTHER, fetchBuildingOther)]);
  yield all([takeLatest(actions.CREATE_BUILDING_INSTALLATION_OTHER, createBuildingOther)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_INSTALLATION_OTHER, updateBuildingOther)]);
}
