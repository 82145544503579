import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchPress(action) {
  try {
    console.log('test test')
    const responseCustomer = yield callApi(() => getRequest('crm/customer'))
    const responseMainTheme = yield callApi(() => getRequest('cms/main-theme'));
    const responseServicePartner = yield callApi(() => getRequest('building/service-partner'));
    const response = yield callApi(() => getRequest(`building/paperpress?buildingId=${action.payload}`))

    yield put({
      type: actions.FETCH_BUILDING_PRESS_SUCCESS,
      payload: response.data,
      payloadCustomer: responseCustomer.data,
      payloadMainTheme: responseMainTheme.data,
      payloadServicePartner: responseServicePartner.data
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_PRESS_FAILURE });
  }
}

function* addPress(action) {
  try {
    const response = yield callApi(() => postRequest(`building/paperpress`, action.payload))
    yield put({
      type: actions.CREATE_BUILDING_PRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_PRESS_FAILURE });
  }
}

function* updatePress(action) {
  try {
    const response = yield callApi(() => putRequest(`building/paperpress?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_PRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_PRESS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_PRESS, fetchPress)]);
  yield all([takeLatest(actions.CREATE_BUILDING_PRESS, addPress)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_PRESS, updatePress)]);
}
