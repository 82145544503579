import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest } from "utils/axios-client";

function* fetchProperties() {
  try {
    const response = yield callApi(() => getRequest('building'))
    yield put({
      type: actions.FETCH_PROPERTIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_PROPERTIES_FAILURE });
  }
}

function* fetchPropertiesWithUser() {
  try {
    const response = yield callApi(() => getRequest(`user/buildings`))
    yield put({
      type: actions.FETCH_PROPERTIES_WITH_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_PROPERTIES_WITH_USER_FAILURE });
  }
}

function* fetchProperty(action) {
  try {
    const response = yield callApi(() => getRequest(`building?id=${action.payload.id}`))
    yield put({
      type: actions.FETCH_PROPERTY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_PROPERTY_FAILURE });
  }
}

function* createProperty(action) {
  try {
    const response = yield callApi(() => postRequest('building', action.payload));
    const responseBuildings = yield callApi(() => getRequest(`user/buildings`))
    yield put({
      type: actions.CREATE_PROPERTY_SUCCESS,
      payload: response.data,
      payloadBuildings: responseBuildings.data
    });
  } catch (error) {
    yield put({ type: actions.CREATE_PROPERTY_FAILURE });
  }
}

function* fetchUserForBuilding() {
  try {
    const response = yield callApi(() => getRequest('building/get_user'))
    yield put({
      type: actions.FETCH_USER_FOR_PROPERTY_SUCCESS,
      payload: response.data.user,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_USER_FOR_PROPERTY_FAILURE });
    yield put({ type: actions.LOGIN_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_PROPERTIES, fetchProperties)]);
  yield all([takeLatest(actions.FETCH_PROPERTIES_WITH_USER, fetchPropertiesWithUser)]);
  yield all([takeLatest(actions.FETCH_PROPERTY, fetchProperty)]);
  yield all([takeLatest(actions.CREATE_PROPERTY, createProperty)]);
  yield all([takeLatest(actions.FETCH_USER_FOR_PROPERTY, fetchUserForBuilding)]);

}
