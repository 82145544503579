import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchBuildingOwner(action) {
  try {
    const response = yield callApi(() => getRequest(`building/owner?buildingId=${action.payload}`))
    yield put({
      type: actions.FETCH_BUILDING_OWNER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_OWNER_FAILURE });
  }
}

function* createBuildingOwner(action) {
  try {
    const response = yield callApi(() => postRequest('building/owner', action.payload))
    yield put({
      type: actions.CREATE_BUILDING_OWNER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_OWNER_FAILURE });
  }
}

function* updateBuildingOwner(action) {
  try {
    const response = yield callApi(() => putRequest('building/owner', action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_OWNER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_OWNER_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_OWNER, fetchBuildingOwner)]);
  yield all([takeLatest(actions.CREATE_BUILDING_OWNER, createBuildingOwner)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_OWNER, updateBuildingOwner)]);
}
